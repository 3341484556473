<template>
  <div>
    <v-app>
      <v-main class="ma-2">
        <v-container class="d-flex justify-center">
          <slot />
        </v-container>
      </v-main>
      <v-footer>
        <AppFooter />
      </v-footer>
    </v-app>
  </div>
</template>
